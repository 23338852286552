'use strict';

var pricingSlider = function () {
  var containers = document.querySelectorAll('.js-features-and-pricing');

  for (var i = containers.length - 1; i >= 0; i--) {
    createControl(containers[i]);
  }
};

var createControl = function (container) {
  var priceMap = JSON.parse(container.dataset.pricingSliderPriceMap);
  var sliderInput = container.querySelector('[data-pricing-slider-control]');
  var numberInput = container.querySelector('[data-pricing-number-input]');
  var countOutputs = container.querySelectorAll('[data-pricing-slider-output-count]');
  var initialCount = container.dataset.pricingSliderInitialCount || 1;

  var priceOutputIndividual = container.querySelector('[data-pricing-slider-output-group="individual"]');
  var priceOutputClinician = container.querySelector('[data-pricing-slider-output-group="clinician"]');
  var priceOutputStudent = container.querySelector('[data-pricing-slider-output-group="student"]');
  var priceOutputResearcher = container.querySelector('[data-pricing-slider-output-group="researcher"]');
  var priceOutputCorporate = container.querySelector('[data-pricing-slider-output-group="corporate"]');

  var priceOutputTotalIndividual = container.querySelector('[data-pricing-slider-output-total-group="individual"]');
  var priceOutputTotalClinician = container.querySelector('[data-pricing-slider-output-total-group="clinician"]');
  var priceOutputTotalStudent = container.querySelector('[data-pricing-slider-output-total-group="student"]');
  var priceOutputTotalResearcher = container.querySelector('[data-pricing-slider-output-total-group="researcher"]');
  var priceOutputTotalCorporate = container.querySelector('[data-pricing-slider-output-total-group="corporate"]');

  var calculatePrice = function (group, count) {
    var groupPricing = priceMap[group];
    var countNumber = Number(count);
    for (var i = 0, m = groupPricing.length; i < m; i += 1) {
      var minNumber = Number(groupPricing[i].min);
      var maxNumber = Number(groupPricing[i].max);
      if (countNumber >= minNumber && countNumber <= maxNumber) {
        return groupPricing[i].price;
      }
    }
  };

  var updateNumberInput = function (count) {
    if (numberInput) {
      numberInput.value = count;
    }
  };

  var updateSliderInput = function (count) {
    if (sliderInput) {
      sliderInput.value = count;
    }
  };

  var updateCountOutput = function (count) {
    if (countOutputs) {
      countOutputs.forEach(function (countOutput) {
        if (countOutput.tagName === 'INPUT') {
          countOutput.value = count;
          countOutput.readOnly = true;
        } else {
          countOutput.innerText = count;
        }
      });
    }
  };

  var currencyOutput = function (number) {
    // return number.toFixed(2);
    return new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(number);
  };

  var handleNewValue = function (count) {
    updateCountOutput(count);

    var individualPrice = calculatePrice('individual', count);
    var clinicianPrice = calculatePrice('clinician', count);
    var studentPrice = calculatePrice('student', count);
    var researcherPrice = calculatePrice('researcher', count);
    var corporatePrice = calculatePrice('corporate', count);

    if (priceOutputIndividual) {
      priceOutputIndividual.innerText = '$' + individualPrice;
    }

    if (priceOutputClinician) {
      priceOutputClinician.innerText = '$' + clinicianPrice;
    }

    if (priceOutputStudent) {
      priceOutputStudent.innerText = '$' + studentPrice;
    }

    if (priceOutputResearcher) {
      priceOutputResearcher.innerText = '$' + researcherPrice;
    }

    if (priceOutputCorporate) {
      priceOutputCorporate.innerText = '$' + corporatePrice;
    }

    if (priceOutputTotalIndividual) {
      priceOutputTotalIndividual.innerText = currencyOutput(individualPrice * count);
    }

    if (priceOutputTotalClinician) {
      priceOutputTotalClinician.innerText = currencyOutput(clinicianPrice * count);
    }

    if (priceOutputTotalStudent) {
      priceOutputTotalStudent.innerText = currencyOutput(studentPrice * count);
    }

    if (priceOutputTotalResearcher) {
      priceOutputTotalResearcher.innerText = currencyOutput(researcherPrice * count);
    }

    if (priceOutputTotalCorporate) {
      priceOutputTotalCorporate.innerText = currencyOutput(corporatePrice * count);
    }
  };

  if (numberInput) {
    numberInput.addEventListener('change', function() {
      var count = numberInput.value;
      updateSliderInput(count);
      handleNewValue(count);
    }, false);
  }

  if (sliderInput) {
    sliderInput.addEventListener('change', function() {
      var count = sliderInput.value;
      updateNumberInput(count);
      handleNewValue(count);
    }, false);
  }

  handleNewValue(initialCount);
  updateSliderInput(initialCount);
  updateNumberInput(initialCount);
}


module.exports = pricingSlider;
