'use strict';

var element = document.querySelector('.js-banner-image');
var img;
var currrentSrc = '';

var bannerImage = function () {
  if (element === null) {
    return;
  }

  img = element.nextElementSibling.querySelector('img');
  responsiveBackgroundImage();
};

function responsiveBackgroundImage() {
  img.addEventListener('load', function() {
    update();
  });

  if (img.complete) {
    update();
  }
}

function update() {
  var newSrc = typeof img.currentSrc === 'undefined' ? img.src : img.currentSrc;

  if (currrentSrc !== newSrc) {
    currrentSrc = newSrc;
    element.style.backgroundImage = 'url("' + currrentSrc + '")';
  }
}

module.exports = bannerImage;
