'use strict';

var tables = document.querySelectorAll('table:not(.c-pricing-table)');

var responsiveTables = function () {
  if (tables === null) {
    return;
  }

  var wrapperClass = 'c-table-wrapper';

  for (var i = tables.length - 1; i >= 0; i--) {
    var wrappingElement = document.createElement('div');
    var table = tables[i];

    wrappingElement.classList.add(wrapperClass);
    table.parentNode.insertBefore(wrappingElement, table);
    wrappingElement.appendChild(table);
  }
};

module.exports = responsiveTables;
