'use strict';

var smoothscroll = require('smoothscroll-polyfill');
var header = document.querySelector('header');
var headerHeight = 0;

var findCorrectClickTarget = function(element, cls) {
  var el = element;

  while (!el.classList.contains(cls)) {
    el = el.parentElement;
  }

  return el;
};

var clickEvent = function(event) {
  event.preventDefault();
  var trigger = findCorrectClickTarget(event.target, 'js-scroll-to');
  var targetPanel = document.querySelector(trigger.getAttribute('href'));
  var topOfElement;

  if (headerHeight == null) {
    topOfElement = targetPanel.offsetTop;
  } else {
    topOfElement = targetPanel.offsetTop - headerHeight;
  }

  var options = {
    top: topOfElement,
    left: 0,
    behavior: 'smooth',
  };

  window.scrollTo(options);

  targetPanel.focus({preventScroll: true});
};

var scrollTo = function() {
  var scrollElements = document.querySelectorAll('.js-scroll-to');

  if (scrollElements == null) {
    return;
  }

  smoothscroll.polyfill();
  headerHeight = header.offsetHeight;

  for (var i = scrollElements.length - 1; i >= 0; i -= 1) {
    scrollElements[i].addEventListener('click', clickEvent);
  }
};

module.exports = scrollTo;
